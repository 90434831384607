.pac-item {
  font-family: 'Raleway', sans-serif !important;
  height: 42px;
  padding-top: 6px;
  padding-left: 10px;
  user-select: none;
  transition: background-color 0.15s ease-in-out;
  
  span {
    font-size: 14px;
    color: var(--ht-text-color);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(black, 0.05);
  }
}

.pac-container.shadow-none {
  box-shadow: none !important;
}
