@use './styles/components/components-index';
@import '@angular/cdk/overlay-prebuilt.css';

:root {

  --ht-app-background: #fcfcfc;
  --ht-widget-background: #ffffff00;

  --ht-secondary-color: #f4f5f7;

  --ht-widget-secondary-color: #0096bb;

  --ht-success-color: #00bd68;
  --ht-success-color-hover: rgb(0, 237, 130, 0.9);

  --ht-action-color: #666666;
  --ht-action-color-hover: rgb(125, 125, 125, 0.9);

  --ht-warn-color: #f68418;
  --ht-warn-color-hover: rgba(247, 150, 59, 0.9);

  --ht-text-color: #525558;
  --ht-text-color-rgb: 82, 85, 88;

  --ht-field-color: #f2f3f6;

  --ht-secondary-grey: #adadad;
  --ht-secondary-grey-2: #999999;

  --ht-secondary-light: #ebebeb;
  --ht-secondary-background: #fcfcfc;

  --ht-raleway-sans: 'Raleway', sans-serif !important;
  --ht-roman-serif: HelveticaNeue-Roman, 'Helvetica Neue Roman', 'Helvetica Neue', Helvetica, Roman,'Lucida Grande', sans-serif;
  
}

html, body {
  background: var(--ht-widget-background);
}
body.no-scroll {
  overflow: hidden;
  overscroll-behavior: none;
}

a {
	color: var(--ht-primary-color);
	text-decoration: none !important;
	font-weight: 400;
	letter-spacing: 0.03em;

  &:hover {
    color:#008cff;
    text-shadow: 0 1px 0 rgba(0,0,0,0.25);
    cursor:pointer;
  }
}

svg.primary {
  fill: var(--ht-primary-color);
}

.host-page-container {
  display: flex;
  justify-content: center;
}

.router-page-container {
  margin-top: 50px;
}

.ht-digit {
  font-family: var(--ht-roman-serif);
}

.mat-progress-spinner.primary circle {
  stroke: var(--ht-primary-color);
}
.mat-progress-spinner[theme="white"] circle {
  stroke: white;
}

ngx-skeleton-loader.search-result-card {
  height: 260px !important;
  &.horizontal {
    height: 220px !important;
  }
}
ngx-skeleton-loader.search-result-card span {
  border-radius: 10px !important;
  height: inherit !important;
}

// To do: remove this globally

#app-root--router-outlet {
  min-height: calc(100dvh - 462px);

  app-search-page-root {
    display: flex;
    flex-direction: column;
    width: 100dvw;
    align-items: center;
  }
}

.ht-no-results {
  color: var(--ht-text-color);
  font-size: 24px;
  font-weight: 600;

  &.grid-element {
    grid-column: 1 / -1;
    text-align: center;
  }
}
