@use '@angular/material' as mat;

.ht-feature-card {
  position: relative;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #f4f4f4;
  $transition-duration: 0.2s;
  transition:
    transform $transition-duration ease-in-out,
    box-shadow $transition-duration ease-in-out !important;
  cursor: pointer;

  &:hover {
    transform: scale(1.01) !important;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175) !important;
    z-index: 1;
  }
}
