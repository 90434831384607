.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--ht-primary-color);
}
.mat-checkbox-checked {
  .mat-checkbox-background {
    background-color: var(--ht-primary-color) !important;
  }
  .mat-checkbox-checkmark-path {
    stroke: white !important;
  }
}
.mat-checkbox-frame {
  border-color: var(--ht-primary-color) !important;
}
