@use './feature-card';

.ht-profile-card {
  @extend .ht-feature-card;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0 0;
  color: var(--ht-text-color);
}
