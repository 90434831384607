
.mat-radio-label-content {
  font-size: 14px;
  font-family: 'Raleway', sans-serif !important;
}

.mat-radio-checked {

  .mat-radio-outer-circle {
    border-color: var(--ht-primary-color) !important;
  }

  .mat-radio-inner-circle {
    background-color: var(--ht-primary-color) !important;
  }
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: var(--ht-primary-color) !important;
}
