@use 'abstracts/component-layers' as component-layers;
@use 'mixins/text' as text;

.leaflet-control-container .leaflet-top.leaflet-left,
.leaflet-control-container .leaflet-bottom.leaflet-right {
  z-index: component-layers.$leaflet-controls;
}

.ht-leaflet-popup {
  display: grid;
  grid-template-rows: 28px 34px;
  grid-template-columns: 90px auto;
  margin: -14px -25px -14px -21px;
  min-width: 270px;
  min-height: 75px;

   > * { margin: 0 !important; }

  img {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    width: 90px !important;
    height: 75px !important;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  
  h2 {
    @include text.text-ellipses(1);
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    font-size: 12px;
    font-weight: 600;
    padding-top: 12px;
    padding-left: 8px;
    transition: 0.3s color ease-in-out;

    &:hover {
      color: var(--ht-primary-color);
      user-select: none;
      cursor: pointer;
    }
  }
  
  p {
    @include text.text-ellipses(3);
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    font-size: 10px;
    line-height: 10px;
    padding-top: 4px;
    padding-left: 8px;
  }
}

.leaflet-div-icon {
  background: rgba(black, 0) !important;
  border: 1px solid rgba(black, 0) !important;
}

.leaflet-marker-icon {
  margin-top: -40px !important;
}

.leaflet-pane.leaflet-popup-pane .leaflet-popup {
  top: -115px;
}

.ht-leaflet-marker {
  position: relative;
  background-color: var(--ht-primary-color);
  border-radius: 1000px;
  $size: 28px;
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;

  &.highlight {
    background-color: #008001 !important;
  }
  &.highlight .ht-leaflet-marker--point {
    border-bottom-color: #008001 !important;
  }
  
  svg {
    transform: scale(0.8) translateY(-1px);
  }

  .ht-leaflet-marker--point {
    position: absolute;
    transform: translateY(18px) rotate(180deg) scale(0.225);
    z-index: -1;
    width: 0;
    height: 0;
    border-left: 55px solid transparent;
    border-right: 55px solid transparent;
    border-bottom: 100px solid var(--ht-primary-color);
  }
}
