.mat-form-field.mat-focused {
  .mat-form-field-ripple {
    background-color: var(--ht-primary-color) !important;
  }
  .mat-form-field-label {
    color: var(--ht-primary-color) !important;
  }
}

.mat-form-field.mat-form-field-appearance-outline {
  $font-family: var(--ht-roman-serif);

  .mat-date-range-input-start-wrapper .mat-date-range-input-mirror,
  .mat-date-range-input-end-wrapper .mat-date-range-input-mirror {
    font-family: $font-family;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    .mat-form-field-flex {
      .mat-form-field-infix {
        padding: 4px 0;
        input.mat-input-element {
        	font-family: $font-family;
          height: 23.5px;
        }
        textarea.mat-input-element {
          resize: none;
        }
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            font-family: $font-family;
            top: 15.5px;
            margin-top: 0;
          }
        }
      }
    }
    .mat-form-field-subscript-wrapper {
      display: none;
    }
  }
  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-17.5px) scale(0.75);
  }
  &.mat-focused {
    .mat-form-field-outline > * {
      border-color: var(--ht-primary-color);
    }
  }
  &:not(.mat-focused) {
    .mat-form-field-label {
      font-family: $font-family;
    }
  }
}

.mat-form-field.mat-form-field-appearance-outline[underlined] {
  .mat-form-field-outline > * {
    border-radius: 0;
    border-color: rgba(black, 0);
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
  &:hover .mat-form-field-outline > * {
    border-bottom-color: rgba(0, 0, 0, 0.87);
  }
  &.mat-focused {
    .mat-form-field-outline > * {
      border-color: rgba(black, 0);
      border-bottom-color: var(--ht-primary-color);
    }
  }
}

.mat-form-field.mat-form-field-appearance-outline[underlined][white] {

  color: white;

  .mat-form-field-label {
    color: rgba(white, 0.5) !important;
  }

  .mat-form-field-outline > * {
    border-bottom-color: rgba(white, 0.12) !important;
  }

  &.mat-focused {
    .mat-form-field-ripple {
      background-color: var(--ht-primary-color) !important;
    }
    .mat-form-field-label {
      color: white !important;
    }
    .mat-input-element {
      caret-color: white;
      color: white;
    }
    .mat-form-field-outline > * {
      border-bottom-color: white !important;
    }
  }
}
