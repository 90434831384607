.hiptraveler-content-editable {

  color: var(--ht-text-color);
  font-size: 18px !important;
  font-weight: 500 !important;

  h2, h3 {
    margin-bottom: 16px !important;
  }
  h2, h2 b, h2 u, h2 i {
    font-size: 28px !important;
  }
  h3, h3 b, h3 u, h3 i {
    font-size: 22px !important;
  }
  p {
    margin-bottom: 8px !important;
  }

  > * iframe,
  > * img {
    border: 5px solid rgba(black, 0);
  }
  > *.highlight iframe,
  > *.highlight img {
    border: 5px solid green;
  }
  
  @mixin default-image-styles {
    object-fit: cover;
    object-position: center;
  }

  .content-editable-youtube-iframe,
  .content-editable-vimeo-iframe,
  .content-editable-inserted-image {
    @include default-image-styles;
    aspect-ratio: 16/9;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
  
  .content-editable-inserted-image {
    @include default-image-styles;
    aspect-ratio: 4/3;
  }

  &::after {
    white-space: pre-wrap !important;
  }
}


.medium-editor-toolbar.medium-editor-stalker-toolbar.medium-toolbar-arrow-under.medium-editor-toolbar-active {
  [data-action="append-h3"] {
    padding-right: 24px !important;
  }
  [data-action="append-blockquote"] {
    display: none;
  }
}
