.features-custom-map-autocomplete {
  opacity: 0;
  animation: fadeIn 0s forwards;
  animation-delay: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
