.mat-calendar-body-selected {
  background-color: var(--ht-primary-color);
}

.mat-calendar-body-in-range::before {
  background: rgba(var(--ht-primary-color-rgb), 0.3);
}

.mat-datepicker-popup {

  .mat-calendar-period-button span,
  .mat-calendar-body-cell-content {
    font-family: var(--ht-roman-serif);
  }
}

.mat-datepicker-content-container .mat-datepicker-actions {

  [matdatepickerapply] {
    background-color: var(--ht-primary-color);
    color: white;
  }
}
