@use '@angular/material' as mat;
@use './feature-card';

.ht-itinerary-feature-card {
  @extend .ht-feature-card;
  @include mat.elevation(2);

  display: grid;
  grid-template-columns: 75px 30% auto;
  border-radius: 10px;
  padding: 12px 12px 12px 0;
  
  @media screen and (max-width: 1300px) {
    // grid-template-columns: 75px 40% auto;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 75px 30% auto;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 75px 35% auto;
  }
  @media screen and (max-width: 700px) {
    grid-template-rows: 200px auto;
    grid-template-columns: 75px auto;

    .itinerary-feature-card--action {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
    }
    .itinerary-feature-card--itinerary-image {
      grid-column: 2 / auto;
      width: 100%;
      height: calc(100% - 12px);
    }
    .itinerary-feature-card--content {
      grid-column: 2 / auto;      
    }
  }
  @media screen and (max-width: 500px) {
    grid-template-rows: 150px auto;
  }
}
