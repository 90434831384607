.mat-select .mat-select-min-line {
  font-family: 'Raleway', sans-serif !important;
}

.mat-select-panel {
  .mat-option {
    $font-size: 2.3em;
    height: $font-size !important;
    line-height: $font-size !important;
  }
  .mat-option-text {
    font-family: 'Raleway', sans-serif !important;
    font-size: 14px !important;
    color: rgba(black, 0.6) !important;
  }
  .mat-option.mat-selected
  .mat-pseudo-checkbox-checked {
    background: var(--ht-primary-color);
  }
  .mat-option.mat-selected
  .mat-option-text {
    color: black;
  }
}

.itinerary-checklist {
  min-height: 0 !important;

  .mat-menu-item {
    $font-height: 2.3em;
    font-family: 'Raleway', sans-serif !important;
    height: $font-height !important;
    line-height: $font-height !important;
    color: rgba(black, 0.6) !important;
  }
}
