.mat-snack-bar-container {
	background-color: var(--ht-primary-color);
  color: white;
  .mat-simple-snackbar-action {
    color: white;
  }
}
@media screen and (max-width: 700px) {
  .mat-snack-bar-container {
    margin-bottom: 94px !important;
  }
}
